import Message from "./Message";

const _404 = () => {
  return (
    <Message>
      <span>404</span>
    </Message>
  );
};

export default _404;
